import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./authentication/auth.guard";

const routes: Routes = [
  {path: '', redirectTo: '/index', pathMatch: 'full'},
  {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  {path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)},
  {path: 'cgu', loadChildren: ()=> import('./cgu/cgu.module').then(m=>m.CguModule)},
  {path: 'confidentialite', loadChildren: ()=> import('./confidentialite/confidentialite.module').then(m=>m.ConfidentialiteModule)},
  {path: 'index', loadChildren: ()=>import('./presentation/presentation.module').then(m =>m.PresentationModule)},
  {path: 'validation-regles', loadChildren: ()=>import('./validation-regles/validation-regles.module').then(m=> m.ValidationReglesModule), canActivate: [AuthGuard]},
  {path: 'scan', loadChildren: ()=>import('./scan/scan.module').then(m=> m.ScanModule), canActivate: [AuthGuard]},
  {path: 'livres', loadChildren: ()=>import('./liste-livres/liste-livres.module').then(m=> m.ListeLivresModule), canActivate: [AuthGuard]},
  {path: 'livres/:id', loadChildren: ()=>import('./livre/livre.module').then(m=> m.LivreModule), canActivate: [AuthGuard]},
  {path: 'prets', loadChildren: ()=>import('./liste-prets/liste-prets.module').then(m=> m.ListePretsModule), canActivate: [AuthGuard]},
  {path: 'musiques', loadChildren: ()=>import('./collection-musiques/collection-musiques.module').then(m=> m.CollectionMusiquesModule), canActivate: [AuthGuard]},
  {path: 'musiques/:id', loadChildren: ()=>import('./musique/musique.module').then(m=> m.MusiqueModule), canActivate: [AuthGuard]},
  {path:'blog', loadChildren: () => import('./liste-billets/liste-billets.module').then(m=>m.ListeBilletsModule)},
  {path: 'blog/:id', loadChildren: ()=>import('./billet/billet.module').then(m=>m.BilletModule)},
  {path: 'a-propos', loadChildren: ()=> import('./apropos/apropos.module').then(m=>m.AproposModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
