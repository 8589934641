import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from "../service/authentication.service";
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Router} from "@angular/router";
import {Utilisateur} from "../model/utilisateur";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('sidenav', {static: true}) sidenav: any;

  public nomUtilisateur: string;
  public events = [];
  public opened: boolean = false;
  public mode: string = 'side';
  public displayToggleSideNav: boolean = false;
  public  isLogin: boolean = false;

  private smallDevice: string[] = [Breakpoints.Handset, Breakpoints.TabletPortrait];

  constructor(private authenticationService: AuthenticationService, private breakpointObserver: BreakpointObserver, private router: Router) {
    const layoutChanges = this.breakpointObserver.observe(this.smallDevice);

    layoutChanges.subscribe(result => {
      this.updateLayoutToDevice(result.matches);
    });

  }


  public toggleSidenav(): void {
    this.sidenav.toggle();
    console.log(this.sidenav.toggle);
  }

  ngOnInit() {
    const infoUtilisateur: Utilisateur = this.authenticationService.getInformationUtilisateur();
    if (infoUtilisateur) {
      this.nomUtilisateur = infoUtilisateur.nom;
      this.isLogin = infoUtilisateur.nom && null != infoUtilisateur.dateValidationCGU;
    }
    const isSmall: boolean = this.breakpointObserver.isMatched(this.smallDevice);
    this.updateLayoutToDevice(isSmall);

    this.authenticationService.getConnectedUtilisateur().subscribe(utilisateur => {
      if (null !== utilisateur) {
        this.nomUtilisateur = utilisateur.nom;
        this.isLogin = utilisateur.nom && null != utilisateur.dateValidationCGU;
      } else {
        this.nomUtilisateur = null;
        this.isLogin = false;
      }

    });
  }

  private updateLayoutToDevice(isSmall: boolean): void {
    this.displayToggleSideNav = isSmall;
    if (isSmall) {
      this.opened = false;
      this.mode = 'over';
    } else {
      this.opened = true;
      this.mode = 'side';
    }
  }

  public logout(): void {
    this.authenticationService.clear();
    this.isLogin = false;
    this.router.navigateByUrl("/index");
  }

  public closeSideNav() {
    if (this.displayToggleSideNav) {
      this.opened = false;
    }
  }

}
