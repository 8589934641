import {Component, Inject} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import {messageType} from "./messageTypeEnum";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent {
  SUCCESS: messageType = messageType.SUCCESS;
  ERROR: messageType = messageType.ERROR;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }

}
