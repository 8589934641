import {Injectable} from '@angular/core';
import {Utilisateur} from "../model/utilisateur";
import {Subject} from "rxjs";

@Injectable()
export class AuthenticationService {
  private static STORAGE_KEY = 'jwt_token';
  private token: string;
  private utilisateur: Subject<Utilisateur> = new Subject<Utilisateur>();
  private isConnected: boolean = null;

  private infosUtilisateur:Utilisateur = null;

  constructor() {
    const token:string = this.getToken();
    if (token) {
      this.infosUtilisateur = this.parseUtilisateurFromToken(token);
    }
  }

  public storeToken(token: string): void {
    if (token !== this.getToken()) {
      sessionStorage.setItem(AuthenticationService.STORAGE_KEY, token);
      this.utilisateur.next(this.parseUtilisateurFromToken(token));
      this.isConnected = true;
    }
  }

  public getToken(): string {
    return sessionStorage.getItem(AuthenticationService.STORAGE_KEY);
  }

  public clear(): void {
    this.utilisateur.next(null);
    this.isConnected = false;
    this.infosUtilisateur = null;
    sessionStorage.removeItem(AuthenticationService.STORAGE_KEY);
  }

  getConnectedUtilisateur(): Subject<Utilisateur> {
    console.log('getConnectedUtilisateur');
    console.log(this.utilisateur);
    return this.utilisateur;
  }

  getInformationUtilisateur():Utilisateur{
    const token:string = this.getToken();
    console.log('token'+token);
    if (token) {
      this.infosUtilisateur = this.parseUtilisateurFromToken(token);
    }
    return this.infosUtilisateur;
  }

  isLogged(): boolean {
    if (null === this.isConnected) {
      if (null !== this.getToken()) {
        this.isConnected = true;
      } else {
        this.isConnected = false;

      }
    }
    return this.isConnected;
  }

  private parseUtilisateurFromToken(token) {
    let utilisateur: Utilisateur = null;

    if (token) {
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('JWT must have 3 parts');
      }

      let informationUtilisateur: any = JSON.parse(this.urlBase64Decode(parts[1]));
      if (informationUtilisateur) {
        let roles: Array<string> = new Array();
        if(informationUtilisateur.grants){
          roles = informationUtilisateur.grants.split(',');
        }
        utilisateur = {
          id: informationUtilisateur.jti,
          nom: informationUtilisateur.sub,
          dateValidationCGU: informationUtilisateur.dateValidationCGU,
          roles: roles
        }
      }
    }

    return utilisateur;
  }

  private urlBase64Decode(str: string): string {
    let output: string = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw 'Illegal base64url string!';
      }
    }
    return window.atob(output);
  }

}
