import {ElementRef, Injectable, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';
import { map, scan } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private isDisplayed: Subject<boolean> = new Subject<boolean>();

  private spinnerRef: OverlayRef = this.createSpinner();

  private spin$ :Subject<boolean> = new Subject();

  constructor(private overlay: Overlay) {
    this.spin$
      .asObservable()
      .pipe(
        map(val => val ? 1 : -1 ),
        scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0)
      )
      .subscribe(
        (res) => {
          if(res === 1){ this.showSpinner() }
          else if( res == 0 ){
            this.spinnerRef.hasAttached() ? this.stopSpinner(): null;
          }
        }
      )
  }

  public showLoader(): void {
    console.log('showLoader');
    // this.isDisplayed.next(true);
    this.spin$.next(true);
  }

  public hideLoader(): void {
    // this.isDisplayed.next(false);
    this.spin$.next(false);
  }

  public isEnable(): Subject<boolean> {
    return this.isDisplayed;
  }

  private createSpinner():OverlayRef{
    return this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });
  }

  private showSpinner(){
    console.log("attach")
    this.spinnerRef.attach(new ComponentPortal(MatSpinner))
  }

  private stopSpinner(){
    console.log("dispose")
    this.spinnerRef.detach() ;
  }
}
