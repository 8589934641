import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Constants} from "../shared/constants";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class UtilisateurService {
  private static UTILISATEUR_RESOURCE_URL = Constants.API_URL + 'login';

  constructor(private http: HttpClient) {
  }

  public login(idToken:string, accessToken:string): Observable<any> {
    const connectionData = {
      idToken: idToken,
      accessToken: accessToken
    };
    console.log("Appel back vers " + UtilisateurService.UTILISATEUR_RESOURCE_URL);
    // this.http.get<any>('http://localhost:8080/'+UtilisateurService.UTILISATEUR_RESOURCE_URL);
    return this.http.post<any>(UtilisateurService.UTILISATEUR_RESOURCE_URL, connectionData);
  }

  public validerCGU(idUtilisateur: number){
    const date:Date = new Date();
   const params = {
      operation: 'UPDATE',
      path: '/dateValidationCgu',
      value: date.toJSON()
    };

    // const params: HttpParams = new HttpParams()
    //   .append('operation', 'UPDATE')
    //   .append('path', '/dateValidationCgu')
    //   .append('value', String(new Date()));
    //
    // const options = {
    //   observe:'response',
    //   params: params
    //
    // };

    return this.http.patch<HttpResponse<ArrayBuffer>>(`/rest/utilisateurs/${idUtilisateur}"`, params);
  }

}
