import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { AppComponent } from './app.component';
import { MessagesComponent } from './messages/messages.component';
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatProgressSpinnerModule, MatSpinner } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { UtilisateurService } from "./service/utilisateur.service";
import { AuthenticationInterceptor } from "./authentication/authentication.interceptor";
import { JwtInterceptor } from "./authentication/jwt.interceptor";
import { AuthenticationService } from "./service/authentication.service";
import { getFrenchPaginatorIntl } from './shared/french-paginator-intl';
import { DashboardModule } from "./dashboard/dashboard.module";
import { MessageService } from './messages/message.service';
import { LoaderService } from "./loader/loader.service";
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [
    AppComponent,
    MessagesComponent
  ],
  imports: [DashboardModule, MatProgressSpinnerModule, OverlayModule, BrowserAnimationsModule, BrowserModule, MatSnackBarModule, AppRoutingModule, HttpClientModule, LayoutModule,
  ],
  entryComponents: [
    MessagesComponent, MatSpinner
  ],
  providers: [UtilisateurService, AuthenticationService, MessageService, LoaderService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true
  }, {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: MatPaginatorIntl, useValue: getFrenchPaginatorIntl()}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
