import {Injectable} from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {AuthenticationService} from "../service/authentication.service";
import {Observable} from "rxjs";
import { tap } from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(public auth: AuthenticationService, private router: Router) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        let authorization: string = event.headers.get('authorization');
        if(authorization) {
          this.auth.storeToken(authorization.substring("Bearer ".length));
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.auth.clear();
          this.router.navigateByUrl("/index");
        }
      }
    }));
  }

}
