import {Injectable} from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import {MessagesComponent} from "./messages.component";
import {messageType} from "./messageTypeEnum";



@Injectable()
export class MessageService {

  constructor(public messageSnackBar: MatSnackBar) {
  }

  private display(message: string, type: messageType) {
    this.messageSnackBar.openFromComponent(MessagesComponent, {
      data:{
        type:type,
        message: message
      },
      duration:2000});
  }

  displaySuccess(message: string){
    this.display(message, messageType.SUCCESS);
  }

  displayError(message: string){
    this.display(message, messageType.ERROR);
  }

}
